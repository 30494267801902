import { Button } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./NoPage.css"; // Import custom CSS styles

function NoPage() {
  let navigate = useNavigate();

  return (
    <Container className="not-found">
      <Row>
        <Col>
          <div className="fourOFour">404</div>
          <div className="noPage">Page Not Found</div>
          <div className="pageExplain">
            The page you are looking for could not be found.
          </div>
          <Button
            className="mt-4"
            variant="contained"
            onClick={() => navigate("/")}
          >
            Return to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NoPage;
