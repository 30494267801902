import { useState } from "react";
import BasicTable from "../../../../Components/BasicTable/BasicTable";
import { notify } from "../../../Dashboard/Dashboard";
import { patients } from "../../PatientsTab/PatientsTab";

interface UserSettingsProps {}

export default function UserSettings({}: UserSettingsProps) {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const revokeAccess = () => {
    alert(
      `Access revoked for ${selectedRows.map((row) => {
        return row.firstName;
      })}`
    );
  };
  return (
    <>
      <BasicTable
        columns={[
          { field: "id", headerName: "ID", flex: 1 },
          {
            field: "firstName",
            headerName: "First name",
            minWidth: 200,
            flex: 1,
          },
          {
            field: "lastName",
            headerName: "Last name",
            minWidth: 100,
            flex: 1,
          },
          {
            field: "dateOfBirth",
            headerName: "Date of Birth",
            minWidth: 100,
            flex: 1,
          },
        ]}
        rows={patients}
        titleIcon="fa-ellipsis-v"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        menuItems={[
          {
            title: "Revoke Access",
            action: revokeAccess,
            disabled: false,
          },
          {
            title: "Select All",
            action: () => notify("On a new page?"),
            disabled: false,
          },
          {
            title: "Select All",
            action: () => notify("On a new page?", "success"),
            disabled: true,
          },
        ]}
        showSearchBar
      />
    </>
  );
}
