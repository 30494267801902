import Box from "@mui/material/Box";
import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Global } from "../../../../Utilities/Global";

interface GeneralSettingsProps {}

export default function GeneralSettings({}: GeneralSettingsProps) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          backgroundColor: Global.backgroundCard,
          borderRadius: "20px",
        }}
      >
        <Typography variant="h4" sx={{ margin: 2 }}>
          Settings
        </Typography>
        <Row className="m-2">
          <Col className="m-2">
            <Typography variant="h5">Account</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Dark Mode"
              />

              <FormControlLabel
                control={<Switch defaultChecked color="primary" />}
                label="Here is a switch"
              />
            </FormGroup>
          </Col>
          <Col className="m-2">
            <Typography variant="h5">Project</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked color="primary" />}
                label="Graph settings"
              />
              <FormControlLabel
                control={<Switch color="primary" />}
                label="High Performance"
              />
            </FormGroup>
          </Col>
          <Col className="m-2">
            <Typography variant="h5">Notifications</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Recieve emails and texts"
              />
              <FormControlLabel
                control={<Switch defaultChecked color="primary" />}
                label="Get latest updates"
              />
              <FormControlLabel
                control={<Switch defaultChecked color="primary" />}
                label="Get latest updates"
              />
            </FormGroup>
          </Col>
        </Row>
      </Box>
    </>
  );
}
