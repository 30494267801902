import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface GeneralCardProps {
  title?: any;
  content?: any;
  buttonText?: string;
  buttonAction?: () => void;
  bottomText?: any;
}

export default function GeneralCard({
  title,
  content,
  buttonText,
  buttonAction,
  bottomText,
}: GeneralCardProps) {
  return (
    <Card
      sx={{
        minWidth: 275,
        margin: 2,
        borderRadius: "20px",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 24, textAlign: "start" }}
          color="text.primary"
        >
          {title}
        </Typography>
        {content}
      </CardContent>
      <CardActions>
        {bottomText ? (
          bottomText
        ) : (
          <Button size="small" onClick={buttonAction}>
            {buttonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
