import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import BasicDialog from "../BasicDialog/BasicDialog";
import { Box } from "@mui/material";
import { Global } from "../../Utilities/Global";
import { EventItem } from "../../Pages/Tabs/ScheduleTab/ScheduleTab";

interface CalendarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle: string;
  dialogChildren: any;
  dialogCancelText?: string;
  dialogActionText?: string;
  dialogActionClick?: () => void;
  eventList?: any[];
  setEventData: React.Dispatch<React.SetStateAction<EventItem>>;
}

// https://fullcalendar.io/docs#toc
export default function Calendar({
  open = false,
  setOpen,
  dialogTitle,
  dialogChildren,
  dialogCancelText,
  dialogActionText,
  dialogActionClick,
  eventList,
  setEventData,
}: CalendarProps) {
  return (
    <>
      <BasicDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={dialogTitle}
        dialogChildren={dialogChildren}
        dialogCancelText={dialogCancelText ? dialogCancelText : undefined}
        dialogActionText={dialogActionText ? dialogActionText : undefined}
        dialogActionClick={dialogActionClick ? dialogActionClick : undefined}
      />

      <Box
        sx={{
          backgroundColor: Global.backgroundCard,
          padding: 2,
          borderRadius: "20px",
          margin: 0,
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek",
          }}
          selectable
          editable
          initialView="dayGridMonth"
          droppable
          events={eventList}
          // Appointment click event
          eventClick={(e) => {
            let eventItem = eventList?.find(
              (event) => event.id === parseInt(e.event.id)
            );
            setEventData(eventItem);
            setOpen(true);
          }}
          // day click event
          select={(e) => {
            let id = 1;
            if (eventList) {
              id = eventList?.length + 1;
            }
            setEventData({
              id: 0,
              title: "",
              start: e.start,
            });
            setOpen(true);
          }}
          eventMaxStack={4}
          dayMaxEventRows={2}
          // working hours
          // slotMinTime={"06:00:00"}
          // slotMaxTime={"22:00:00"}
          displayEventTime={false}
        />
      </Box>
    </>
  );
}
