import * as React from "react";
import BasicTable from "../../../Components/BasicTable/BasicTable";
import { useEffect, useState } from "react";
import Charting, { Patient } from "./Charting/Charting";
import { Button, Grid, Icon } from "@mui/material";
import { Global } from "../../../Utilities/Global";
import BasicDialog from "../../../Components/BasicDialog/BasicDialog";

interface PatientsTabProps {
  patient?: Patient;
  tabContext: any;
  setTabContext: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export const patients = [
  {
    id: 1,
    lastName: "Snow",
    firstName: "Jon",
    dateOfBirth: new Date(2003, 7, 3).toLocaleDateString(),
  },
  {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    dateOfBirth: new Date(2013, 3, 30).toLocaleDateString(),
  },
  {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    dateOfBirth: new Date(1990, 11, 2).toLocaleDateString(),
  },
  {
    id: 4,
    lastName: "Stark",
    firstName: "Arya",
    dateOfBirth: new Date(1989, 10, 12).toLocaleDateString(),
  },
  {
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    dateOfBirth: null,
  },
  {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    dateOfBirth: new Date(1995, 4, 17).toLocaleDateString(),
  },
  {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    dateOfBirth: new Date(1943, 1, 3).toLocaleDateString(),
  },

  {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    dateOfBirth: new Date(2001, 2, 27).toLocaleDateString(),
  },
  {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    dateOfBirth: new Date(2005, 8, 12).toLocaleDateString(),
  },
];

export default function PatientsTab({
  patient,
  tabContext,
  setTabContext,
}: PatientsTabProps) {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentPatient, setCurrentPatient] = useState(patient);
  const [showNewPatient, setShowNewPatient] = useState(false);
  useEffect(() => {
    if (tabContext) {
      setCurrentPatient(tabContext);
    }
  }, [tabContext]);

  useEffect(() => {}, []);

  return (
    <Grid container>
      {currentPatient ? (
        <Grid item>
          <Button
            onClick={() => {
              setTabContext(undefined);
              setCurrentPatient(undefined);
            }}
          >
            <Icon className="fa fa-arrow-left" sx={{ marginRight: 2 }} />{" "}
            Patient List
          </Button>
          <Charting patient={currentPatient} />
        </Grid>
      ) : (
        <Grid item xs={11}>
          <BasicDialog
            open={showNewPatient}
            setOpen={setShowNewPatient}
            dialogTitle={"New Patient"}
            dialogChildren={<>I am a new patient</>}
            dialogCancelText="Cancel"
            dialogActionText="Create"
            dialogActionClick={() => {
              alert(3);
              setShowNewPatient(false);
            }}
          />
          <BasicTable
            columns={[
              {
                field: "firstName",
                headerName: "First Name",
                minWidth: 200,
                flex: 1,
              },
              {
                field: "lastName",
                headerName: "Last Name",
                minWidth: 100,
                flex: 1,
              },
              {
                field: "dateOfBirth",
                headerName: "Date of Birth",
                minWidth: 100,
                flex: 1,
              },
              {
                field: "action",
                headerName: "",

                renderCell: (cellValue: any) => {
                  return (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: Global.secondaryColor,
                        ":hover": {
                          backgroundColor: Global.secondaryDarkColor,
                        },
                      }}
                      onClick={() =>
                        setCurrentPatient(cellValue.row as Patient)
                      }
                    >
                      View
                    </Button>
                  );
                },
                maxWidth: 100,
                flex: 1,
              },
            ]}
            rows={patients}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            actionButtonAction={() => setShowNewPatient(true)}
            actionButtonText={<i className="fa fa-plus"></i>}
            showSearchBar
            checkBoxSelection={false}
          />
        </Grid>
      )}
    </Grid>
  );
}
