import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { TabPanel } from "../../AdminTab/AdminTab";
import BasicTable from "../../../../Components/BasicTable/BasicTable";
import { Global } from "../../../../Utilities/Global";
import { notify } from "../../../Dashboard/Dashboard";
import GeneralCard from "../../../../Components/GeneralCard/GeneralCard";
import BasicDialog from "../../../../Components/BasicDialog/BasicDialog";

export interface Patient {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

interface ChartingProps {
  patient?: Patient;
}

export default function Charting({ patient }: ChartingProps) {
  const [tab, setTab] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [noteText, setNoteText] = useState("");
  const [notesList, setNotesList] = useState<string[]>([]);
  const [assessmentList, setAssessmentList] = useState([
    {
      id: 1,
      dateCreated: new Date().toDateString(),
      status: {
        text: "Complete",
        color: Global.successColor,
      },
      file: "",
    },
    {
      id: 2,
      dateCreated: new Date().toDateString(),
      status: {
        text: "Incomplete",
        color: Global.errorColor,
      },
      file: "",
    },
  ]);
  const [open, setOpen] = useState(false);

  // PDF viewer
  // pdf file onChange state
  const [pdfFile, setPdfFile] = useState<string | undefined>(undefined);

  // handle file onChange event
  const allowedFiles = ["application/pdf"];
  const handleFile = (e: any) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && allowedFiles.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          if (e.target) {
            setPdfFile(e.target.result as string);
          }
        };
      } else {
        setPdfFile("");
      }
    }
  };

  return (
    <>
      <BasicDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={"View PDF"}
        maxWidth="xl"
        dialogChildren={
          <>
            <Box sx={{ width: "80vw" }}>
              {/* Upload PDF */}
              <input
                type="file"
                className="form-control"
                onChange={handleFile}
              ></input>
              <iframe
                title="PDF Viewer"
                style={{ height: "80vh", width: "80vw" }}
                src={pdfFile}
              ></iframe>
            </Box>
          </>
        }
        dialogCancelText="Cancel"
        dialogActionText="Save"
        dialogActionClick={() => {
          setOpen(false);
          notify("PDF saved");
        }}
      />
      <Grid container wrap="wrap">
        {/* Left Panel */}
        <Grid item sm={12} md={4}>
          <GeneralCard
            content={
              <Grid container>
                <Grid item xs={12}>
                  <Avatar
                    sx={{ width: 120, height: 120 }}
                    src="https://i.pravatar.cc/300?img=11"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">{`${patient?.firstName} ${patient?.lastName}`}</Typography>
                </Grid>
                <Box sx={{ marginTop: 2 }}>
                  {/* Patient Info */}
                  <Accordion
                    sx={{ backgroundColor: "transparent" }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={
                        <i
                          className="fa fa-chevron-down"
                          style={{ color: Global.primaryColor }}
                        ></i>
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5">Patient Demographics</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container sx={{ rowGap: 1.5 }}>
                        {/* Email */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Email</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ color: Global.primaryColor }}>
                            tempEmail@gmail.com
                          </Typography>
                        </Grid>
                        {/* Phone */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Phone Number</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ color: Global.primaryColor }}>
                            801 123 4567
                          </Typography>
                        </Grid>
                        {/* Date of Birth */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Date of Birth</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{}}>
                            {new Date().toLocaleDateString()}
                          </Typography>
                        </Grid>
                        {/* Gender */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Gender</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{}}>Male</Typography>
                        </Grid>
                        {/* Address */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Address</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{}}>
                            1234 East 5678 South, Provo, UT 84606
                          </Typography>
                        </Grid>
                        {/* Insurance */}
                        <Grid item xs={6}>
                          <Typography sx={{}}>Insurance</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{}}>Yes </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Notes */}
                  <Accordion
                    sx={{ backgroundColor: "transparent" }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={
                        <i
                          className="fa fa-plus"
                          style={{ color: Global.primaryColor }}
                        ></i>
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5">Notes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        label="Notes"
                        sx={{ width: "100%" }}
                        multiline
                        rows={3}
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (noteText) {
                              setNotesList([...notesList, noteText]);
                              setNoteText("");
                              notify(
                                `Note added to ${patient?.firstName}'s history`
                              );
                            } else {
                              notify("Add text to add a note", "warning");
                            }
                          }}
                        >
                          <i className="fa fa-plus mr-2"></i>Note
                        </Button>
                      </Box>
                      <Grid container direction={"column-reverse"}>
                        {notesList.map((note, index) => {
                          return (
                            <GeneralCard
                              title={
                                <Icon
                                  className="fa fa-times float-right"
                                  sx={{ color: "red", cursor: "pointer" }}
                                  onClick={() => {
                                    let tempNotesList = [...notesList];
                                    tempNotesList.splice(index, 1);
                                    setNotesList(tempNotesList);
                                    notify(
                                      `Note deleted from ${patient?.firstName}'s history`
                                    );
                                  }}
                                ></Icon>
                              }
                              bottomText={
                                <Box
                                  sx={{
                                    fontSize: ".75rem",
                                    color: "grey",
                                  }}
                                >
                                  -Ammon {new Date().toLocaleString()}
                                </Box>
                              }
                              content={
                                <Box>
                                  <Box>{note}</Box>
                                </Box>
                              }
                            />
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            }
          />
        </Grid>

        {/* Right Panel */}
        <Grid item sm={12} md={8}>
          <Box>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
            >
              <Tabs value={tab}>
                <Tab label="Assessments" onClick={() => setTab(0)} />
                <Tab label="Patient History" onClick={() => setTab(1)} />
                <Tab label="Medications" onClick={() => setTab(2)} />
                <Tab label="Orders" onClick={() => setTab(3)} />
                <Tab label="Care Plan" onClick={() => setTab(4)} />
              </Tabs>
            </Box>
            <Box>
              {/* Patient Info */}
              <TabPanel value={tab} index={0}>
                <Button
                  sx={{ margin: 2 }}
                  variant="contained"
                  onClick={() =>
                    setAssessmentList([
                      ...assessmentList,
                      {
                        id: assessmentList.length + 1,
                        dateCreated: new Date().toDateString(),
                        status: {
                          text: "Incomplete",
                          color: Global.errorColor,
                        },
                        file: "",
                      },
                    ])
                  }
                >
                  <Icon className="fa fa-plus mr-2"></Icon>New Assessment
                </Button>
                <Box>
                  <BasicTable
                    columns={[
                      {
                        field: "dateCreated",
                        headerName: "Date Created",
                        minWidth: 200,
                        flex: 1,
                      },
                      {
                        field: "status",
                        headerName: "Status",
                        flex: 1,
                        renderCell: (cellValue: any) => {
                          return (
                            <Typography
                              sx={{
                                textAlign: "center",
                                color: cellValue.value.color,
                              }}
                            >
                              {cellValue.value.text}
                            </Typography>
                          );
                        },
                      },
                      {
                        field: "file",
                        headerName: "",
                        renderCell: (cellValue: any) => {
                          return (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: Global.secondaryColor,
                                ":hover": {
                                  backgroundColor: Global.secondaryDarkColor,
                                },
                              }}
                              onClick={() => {
                                //here we would also set the file to display
                                setOpen(true);
                              }}
                            >
                              Edit
                            </Button>
                          );
                        },
                        maxWidth: 100,
                        flex: 1,
                      },
                    ]}
                    rows={assessmentList}
                    title={`${patient?.firstName}'s Assessments`}
                    titleIcon="fa-ellipsis-v"
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    checkBoxSelection={false}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Box>
                  <BasicTable
                    columns={[
                      {
                        field: "fileType",
                        headerName: "File Type",
                        minWidth: 200,
                        flex: 1,
                      },
                      {
                        field: "dateCreated",
                        headerName: "Date Created",
                        minWidth: 200,
                        flex: 1,
                      },
                      {
                        field: "status",
                        headerName: "Status",
                        flex: 1,
                        renderCell: (cellValue: any) => {
                          return (
                            <Typography
                              sx={{
                                textAlign: "center",
                                color: cellValue.value.color,
                              }}
                            >
                              {cellValue.value.text}
                            </Typography>
                          );
                        },
                      },
                      {
                        field: "file",
                        headerName: "",
                        renderCell: (cellValue: any) => {
                          return (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: Global.secondaryColor,
                                ":hover": {
                                  backgroundColor: Global.secondaryDarkColor,
                                },
                              }}
                              onClick={() => {
                                //here we would also set the file to display
                                setOpen(true);
                              }}
                            >
                              Edit
                            </Button>
                          );
                        },
                        maxWidth: 100,
                        flex: 1,
                      },
                    ]}
                    rows={[
                      {
                        id: 1,
                        fileType: "Past Medical History",
                        dateCreated: new Date().toDateString(),
                        status: {
                          text: "Complete",
                          color: Global.successColor,
                        },
                        file: "",
                      },
                      {
                        id: 2,
                        fileType: "Social History",
                        dateCreated: new Date().toDateString(),
                        status: {
                          text: "Incomplete",
                          color: Global.errorColor,
                        },
                        file: "",
                      },
                    ]}
                    title={`${patient?.firstName}'s History`}
                    titleIcon="fa-ellipsis-v"
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    menuItems={[
                      {
                        title: "Patient Info",
                        action: () => {
                          alert(1);
                        },
                        disabled: false,
                      },
                      {
                        title: "Select All",
                        action: () => alert("hey"),
                        disabled: true,
                      },
                    ]}
                    checkBoxSelection={false}
                  />
                </Box>
              </TabPanel>
              {/* Medications */}
              <TabPanel value={tab} index={2}>
                <Grid container>
                  <Grid item>
                    <GeneralCard
                      bottomText={null}
                      content={
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h5">Labetalol</Typography>
                            <IconButton
                              className="fa fa-edit"
                              sx={{
                                color: Global.primaryColor,
                              }}
                            ></IconButton>
                          </Box>
                          <hr />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Chip
                              label="100mg"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Twice a day"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Oral"
                              variant="outlined"
                              color="primary"
                            />
                          </Box>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GeneralCard
                      bottomText={null}
                      content={
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h5">Labetalol</Typography>
                            <IconButton
                              className="fa fa-edit"
                              sx={{
                                color: Global.primaryColor,
                              }}
                            ></IconButton>
                          </Box>
                          <hr />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Chip
                              label="100mg"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Twice a day"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Oral"
                              variant="outlined"
                              color="primary"
                            />
                          </Box>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GeneralCard
                      bottomText={null}
                      content={
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h5">Labetalol</Typography>
                            <IconButton
                              className="fa fa-edit"
                              sx={{
                                color: Global.primaryColor,
                              }}
                            ></IconButton>
                          </Box>
                          <hr />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Chip
                              label="100mg"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Twice a day"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Oral"
                              variant="outlined"
                              color="primary"
                            />
                          </Box>
                        </>
                      }
                    />
                  </Grid>{" "}
                  <Grid item>
                    <GeneralCard
                      bottomText={null}
                      content={
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h5">Labetalol</Typography>
                            <IconButton
                              className="fa fa-edit"
                              sx={{
                                color: Global.primaryColor,
                              }}
                            ></IconButton>
                          </Box>
                          <hr />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Chip
                              label="100mg"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Twice a day"
                              variant="outlined"
                              color="primary"
                            />
                            <Chip
                              label="Oral"
                              variant="outlined"
                              color="primary"
                            />
                          </Box>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={3}>
                Orders
              </TabPanel>
              <TabPanel value={tab} index={4}>
                Care Plan
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
