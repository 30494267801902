import { Card, CardContent, Grid, Icon, Typography, Box } from "@mui/material";
import { Global } from "../../Utilities/Global";

export default function InfoCards() {
  return (
    <Box sx={{ marginTop: 4, marginBottom: 4 }}>
      <Grid
        container
        spacing={5}
        sx={{
          justifyContent: "center",
        }}
      >
        <Grid item>
          <Card sx={{ textAlign: "center", width: 300 }}>
            <Icon
              className="fa fa-check"
              sx={{
                color: Global.secondaryColor,
                marginTop: 2,
                fontSize: "3rem",
              }}
            ></Icon>
            <CardContent>
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                Easy to Use
              </Typography>
              <Typography
                component="p"
                color="text.secondary"
                sx={{ height: 100 }}
              >
                We focus on making your life as physician easy by simplifying
                the whole process.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ textAlign: "center", width: 300 }}>
            <Icon
              className="fa fa-money"
              sx={{
                color: Global.secondaryColor,
                marginTop: 2,
                fontSize: "3rem",
              }}
            ></Icon>
            <CardContent>
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                Best Deals
              </Typography>
              <Typography
                component="p"
                color="text.secondary"
                sx={{ height: 100 }}
              >
                We provide the best service for the lowest price, so you can
                focus on what matters, your patients.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ textAlign: "center", width: 300 }}>
            <Icon
              className="fa fa-clock-o"
              sx={{
                color: Global.secondaryColor,
                marginTop: 2,
                fontSize: "3rem",
              }}
            ></Icon>
            <CardContent>
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                24/7 Support
              </Typography>
              <Typography
                component="p"
                color="text.secondary"
                sx={{ height: 100 }}
              >
                Our team is available around the clock to assist you with any
                questions or concerns you may have.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
