import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Fab, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Menu, { listItem } from "../Menu/Menu";
import { Global } from "../../Utilities/Global";
import "./BasicTable.css";

interface BasicTableProps {
  columns: GridColDef[];
  rows: any[];
  title?: string;
  titleIcon?: any;
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  selectedRows?: any[];
  menuItems?: listItem[];
  showSearchBar?: boolean;
  showToolBar?: boolean;
  checkBoxSelection?: boolean;
  actionButtonText?: any;
  actionButtonAction?: () => void;
}

export default function BasicTable({
  columns,
  rows,
  title,
  titleIcon,
  selectedRows,
  setSelectedRows,
  menuItems,
  showSearchBar = false,
  showToolBar = true,
  checkBoxSelection = true,
  actionButtonAction,
  actionButtonText,
}: BasicTableProps) {
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  // Logic for selecting rows
  useEffect(() => {
    let selectedItems: any[] = [];
    rows.map((row) => {
      if (selectedIds.includes(row.id)) {
        selectedItems.push(row);
      }
    });
    if (setSelectedRows) {
      setSelectedRows(selectedItems);
    }
  }, [selectedIds]);

  // Logic for the seach bar
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredRows(rows);
    } else if (rows.length > 0) {
      let rowKeys = Object.keys(rows[0]);
      let filteredItems: any[] = rows.filter((item) => {
        for (let key of rowKeys) {
          if (
            item[key] !== null &&
            item[key]
              .toString()
              .toUpperCase()
              .includes(searchTerm.toUpperCase())
          ) {
            return item;
          }
        }
      });
      setFilteredRows(filteredItems);
    }
  }, [searchTerm, rows]);

  return (
    <div
      className="shadow"
      style={{
        //this width needs to be changed
        // maxWidth: "100%",
        // minWidth: "100%",
        backgroundColor: Global.backgroundCard,
        borderRadius: "20px",
      }}
    >
      {showToolBar && (
        <Toolbar
          sx={{
            justifyContent: "space-between",
            backgroundColor: Global.primaryColor,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            color: "whitesmoke",
          }}
        >
          <Box sx={{ margin: 2, textAlign: "start" }}>
            {title && (
              <Typography variant="h6" id="tableTitle" component="div">
                {title}
              </Typography>
            )}
            {showSearchBar && (
              <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                variant="filled"
                sx={{
                  marginTop: 1,
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
            )}
          </Box>
          {titleIcon && menuItems && (
            <Menu
              icon={titleIcon}
              menuListItems={menuItems}
              iconSx={{ color: "whitesmoke" }}
            />
          )}
          {actionButtonAction && actionButtonText && (
            <Fab
              onClick={actionButtonAction}
              sx={{
                backgroundColor: Global.secondaryColor,
                ":hover": { backgroundColor: Global.secondaryDarkColor },
                color: Global.textColorWhite,
              }}
            >
              {actionButtonText}
            </Fab>
          )}
        </Toolbar>
      )}
      <DataGrid
        sx={{ maxHeight: "78vh" }}
        rows={filteredRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        checkboxSelection={checkBoxSelection}
        onRowSelectionModelChange={setSelectedIds}
        disableRowSelectionOnClick
      />
    </div>
  );
}
