import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  IconButton,
  Divider,
  Typography,
  ListItemButton,
  CssBaseline,
  List,
  Toolbar,
  Box,
  Container,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import AccountMenu from "../../Components/AccountMenu/AccountMenu";
import ScheduleTab from "../Tabs/ScheduleTab/ScheduleTab";
import "font-awesome/css/font-awesome.min.css";
import { Global } from "../../Utilities/Global";
import AdminTab from "../Tabs/AdminTab/AdminTab";
import PatientsTab from "../Tabs/PatientsTab/PatientsTab";
import "./Dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TasksTab from "../Tabs/TasksTab/TasksTab";

const drawerWidth = 200;
export const notify = (message: string, type: string = "success") => {
  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "success":
    default:
      toast.success(message);
      break;
  }
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface NavItem {
  name: string;
  icon: string;
  disabled?: boolean;
  subItems?: NavItem[];
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState("Schedule");
  const sideNavList = [
    { name: "Schedule", icon: "table" },
    { name: "Patients", icon: "users" },
    { name: "Tasks", icon: "tasks" },
    { name: "Admin", icon: "user" },
    { name: "Logout", icon: "sign-out", disabled: false },
  ];
  const [tabContext, setTabContext] = useState<any[] | undefined>();

  let navigate = useNavigate();

  const renderTab = () => {
    switch (currentTab) {
      case "Admin":
        return (
          <AdminTab
            setCurrentTab={setCurrentTab}
            tabContext={tabContext}
            setTabContext={setTabContext}
          />
        );

      case "Patients":
        return (
          <PatientsTab tabContext={tabContext} setTabContext={setTabContext} />
        );
      case "Tasks":
        return (
          <TasksTab tabContext={tabContext} setTabContext={setTabContext} />
        );
      case "Schedule":
      default:
        return (
          <ScheduleTab tabContext={tabContext} setTabContext={setTabContext} />
        );
    }
  };

  const renderListItem = (navItem: NavItem, index: number) => {
    return (
      <List key={index} className={`rounded`}>
        <ListItem disablePadding>
          <ListItemButton
            className="rounded ml-2 mr-2"
            disabled={navItem.disabled ? true : false}
            selected={currentTab === navItem.name}
            sx={{
              "&:hover": {
                background: Global.itemHoverState,
              },
              "&.Mui-selected:hover": {
                backgroundColor: Global.secondaryLightColor,
                color: "white",
              },
              "&.Mui-selected": {
                backgroundColor: Global.secondaryColor,
                color: "white",
              },
            }}
            onClick={() => {
              if (navItem.name !== "Logout") {
                setCurrentTab(navItem.name);
              } else {
                navigate("/", { replace: true });
              }
            }}
          >
            <ListItemIcon
              className={`fa-lg fa fa-${navItem.icon} ${
                currentTab === navItem.name ? "text-white" : ""
              }`}
            ></ListItemIcon>
            <ListItemText primary={navItem.name} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <>
          <AppBar
            position="fixed"
            open={open}
            sx={{ backgroundColor: Global.primaryColor }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
                className="fa fa-chevron-right"
              ></IconButton>

              <Container
                sx={{
                  display: "flex",
                  justifyContent: !open ? "space-between" : "end",
                }}
                maxWidth={false}
              >
                {!open && (
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    className="mt-1"
                    sx={{ color: Global.textColorWhite }}
                  >
                    MangoEMR
                  </Typography>
                )}
                <AccountMenu
                  accountName={"Ammon Warnick"}
                  buttonStyle="light"
                  setCurrentTab={setCurrentTab}
                />
              </Container>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ backgroundColor: Global.primaryColor }}>
              <Typography
                variant="h5"
                noWrap
                component="div"
                className="mt-1"
                sx={{ color: Global.textColorWhite }}
              >
                MangoEMR
              </Typography>
              <IconButton
                onClick={() => setOpen(false)}
                className="fa fa-chevron-left"
              ></IconButton>
            </DrawerHeader>
            <Divider sx={{ marginTop: 0 }} />
            {sideNavList.map((navItem, index) => {
              return renderListItem(navItem, index);
            })}
          </Drawer>
        </>
        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: Global.backgroundColor,
            height: "100vh",
          }}
        >
          <DrawerHeader />
          {renderTab()}
        </Box>
      </Box>
    </>
  );
}
