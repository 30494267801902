import * as React from "react";
import { Box, TextField, Grid, Autocomplete, Button } from "@mui/material";
import { useState, useEffect } from "react";
import Calendar from "../../../Components/Calendar/Calendar";
import { notify } from "../../Dashboard/Dashboard";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Global } from "../../../Utilities/Global";
import dayjs from "dayjs";
import axios from "axios";

interface ScheduleTabProps {
  tabContext: any;
  setTabContext: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export default function TasksTab({
  tabContext,
  setTabContext,
}: ScheduleTabProps) {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const patientList = ["Ammon", "Gabrielle", "Roman"];

  return (
    <>
      <Box sx={{ width: "100%" }}>Tasks Tab</Box>
    </>
  );
}
