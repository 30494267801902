export namespace Global {
  export const primaryColor: string = "#2196f3";
  export const primaryLightColor: string = "#78bdf5";
  export const secondaryColor: string = "#F9CA26";
  export const secondaryDarkColor: string = "#d4b502";
  export const secondaryLightColor: string = "#f7d457";
  export const errorColor: string = "#e64a19";
  export const successColor: string = "#388e3c";

  export const textColor: string = "#000000";
  export const textColorWhite: string = "#FFFFFF";
  export const textColorMuted: string = "#949494";

  export const backgroundColor: string = "#F6F6F6";
  export const backgroundCard: string = "#FFFFFF";

  export const itemHoverState: string = "whitesmoke";
}
