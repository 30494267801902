import * as React from "react";
import { Box, TextField, Grid, Autocomplete, Button } from "@mui/material";
import { useState, useEffect } from "react";
import Calendar from "../../../Components/Calendar/Calendar";
import { notify } from "../../Dashboard/Dashboard";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Global } from "../../../Utilities/Global";
import dayjs from "dayjs";
import axios from "axios";

interface ScheduleTabProps {
  tabContext: any;
  setTabContext: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export interface EventItem {
  id?: number;
  title: string;
  start: Date;
  notes?: string;
}

export default function ScheduleTab({
  tabContext,
  setTabContext,
}: ScheduleTabProps) {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [eventList, setEventList] = useState<EventItem[]>();

  const [newEvent, setNewEvent] = useState<EventItem>({
    id: 0,
    title: "",
    start: new Date(),
    notes: "",
  });
  const patientList = ["Ammon", "Gabrielle", "Roman"];

  const addNewEvent = () => {
    // redo this whole section for easier logic
    // Edit appointment

    // create appointmnet
    if (newEvent.id === 0) {
      setOpen(false);
      axios
        .post(`http://localhost:3001/addEvent`, {
          headers: { "Content-Type": "application/json" },
          newEvent,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.result);

            notify("New appointment added");
            setEventList(res.data.result);
            setNewEvent({
              id: 0,
              title: "Ammon",
              start: new Date(),
              notes: "",
            });
          }
        })
        .catch((res) => {});
    } else if (newEvent.id) {
      notify("");
    } else {
      notify("Please fill out the rest of the form", "error");
    }
  };

  const deleteEventItem = () => {
    axios
      .post(`http://localhost:3001/deleteEventItem`, {
        headers: { "Content-Type": "application/json" },
        newEvent,
      })
      .then((res) => {
        if (res.data.success) {
          setEventList(res.data.eventList);
          setOpen(false);
          notify("Item deleted");
        }
      })
      .catch((res) => {
        notify("Could not find appointment data", "error");
      });
  };

  useEffect(() => {
    axios
      .post(`http://localhost:3001/getAllEvents`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setEventList(res.data.eventList);
      })
      .catch((res) => {
        notify("Could not find appointment data", "error");
      });
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ margin: 4, height: "80vh" }}>
          <Calendar
            open={open}
            setOpen={setOpen}
            dialogTitle={"Create an Appointment"}
            dialogActionText={"Create"}
            dialogCancelText="Cancel"
            dialogActionClick={addNewEvent}
            eventList={eventList}
            setEventData={setNewEvent}
            dialogChildren={
              <Grid container gap={2}>
                <Grid item xs={12} md={10} sx={{ marginTop: 1 }}>
                  <Autocomplete
                    options={patientList}
                    renderInput={(params) => (
                      <TextField {...params} label="Patient" />
                    )}
                    value={newEvent.title}
                    onChange={(event: any, newValue: string | null) => {
                      if (newValue) {
                        setNewEvent({ ...newEvent, title: newValue });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <Button
                    sx={{ color: Global.errorColor, marginTop: 2 }}
                    onClick={() => deleteEventItem()}
                  >
                    Delete
                  </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={dayjs(newEvent.start)}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          start: dayjs(e).toDate(),
                        })
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Time"
                      value={dayjs(newEvent.start)}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          start: dayjs(e).toDate(),
                        })
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Duration"
                    variant="outlined"
                    type="number"
                    value={15}
                    // onChange={(e) =>
                    //   setNewEvent({ ...newEvent, title: e.target.value })
                    // }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Notes:"
                    variant="standard"
                    value={newEvent.notes}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, notes: e.target.value })
                    }
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            }
          />
        </Box>
      </Box>
    </>
  );
}
