import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import UserSettings from "./UserSettings/UserSettings";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface AdminTabProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  tabContext: any;
  setTabContext: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export default function AdminTab({
  setCurrentTab,
  tabContext,
  setTabContext,
}: AdminTabProps) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs value={tab}>
            <Tab label="General" onClick={() => setTab(0)} />
            <Tab label="Users" onClick={() => setTab(1)} />
            <Tab label="Settings" onClick={() => setTab(2)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={tab} index={0}>
            <GeneralSettings />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <UserSettings />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Typography variant="h5">Settings</Typography>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
