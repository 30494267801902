import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { Global } from "../../Utilities/Global";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

interface BasicDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle: string;
  dialogChildren: any;
  dialogCancelText?: string;
  dialogActionText?: string;
  dialogActionClick?: () => void;
  setDraggable?: boolean;
  maxWidth?: DialogProps["maxWidth"];
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function BasicDialog({
  open,
  setOpen,
  dialogTitle,
  dialogChildren,
  dialogCancelText,
  dialogActionText,
  dialogActionClick,
  setDraggable = false,
  maxWidth = "md",
}: BasicDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{ borderRadius: "20px" }}
      maxWidth={maxWidth}
    >
      <DialogTitle id="draggable-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogChildren}</DialogContent>
      <DialogActions
        sx={{
          marginBottom: 2,
          marginLeft: 1,
          marginRight: 1,
          justifyContent: "space-between",
        }}
      >
        {dialogCancelText && (
          <Button
            sx={{ color: Global.errorColor }}
            onClick={() => setOpen(false)}
          >
            {dialogCancelText}
          </Button>
        )}
        {dialogActionText && (
          <Button
            variant="contained"
            onClick={() => {
              if (dialogActionClick) {
                dialogActionClick();
              }
            }}
            autoFocus
          >
            {dialogActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
