import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Button, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./Home/Home";
import Pricing from "./Pricing/Pricing";
import Footer from "../../Components/Footer/Footer";
import { Global } from "../../Utilities/Global";
import { ToastContainer } from "react-toastify";

export default function Landing() {
  let navigate = useNavigate();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [currentPage, setCurrentPage] = useState("home");
  const navLinks = [
    <Typography
      noWrap
      component="div"
      sx={{
        marginLeft: 2,
        marginRight: 2,
        marginTop: 1,
        cursor: "pointer",
        borderBottom: `${
          currentPage === "about"
            ? `2px solid ${Global.secondaryColor}`
            : "2px solid transparent"
        }`,
        ":hover": { borderBottom: `2px solid ${Global.secondaryColor}` },
      }}
      onClick={() => setCurrentPage("about")}
    >
      About Us
    </Typography>,
    <Typography
      noWrap
      component="div"
      sx={{
        marginLeft: 2,
        marginRight: 2,
        marginTop: 1,
        cursor: "pointer",
        borderBottom: `${
          currentPage === "pricing"
            ? `2px solid ${Global.secondaryColor}`
            : "2px solid transparent"
        }`,
        ":hover": { borderBottom: `2px solid ${Global.secondaryColor}` },
      }}
      onClick={() => {
        // notify("Feature not ready yet", "warning");
        setCurrentPage("pricing");
      }}
    >
      Pricing
    </Typography>,

    <Typography
      noWrap
      component="div"
      sx={{
        marginLeft: 2,
        marginRight: 2,
        marginTop: 1,
        cursor: "pointer",
        borderBottom: "2px solid transparent",
        ":hover": { borderBottom: `2px solid ${Global.secondaryColor}` },
      }}
      onClick={() => navigate("/login")}
    >
      Login
    </Typography>,
    <Button
      variant="contained"
      sx={{
        marginLeft: 2,
        marginRight: 2,
        borderBottom: "2px solid transparent",
        backgroundColor: Global.secondaryColor,
        ":hover": { backgroundColor: Global.secondaryDarkColor },
      }}
      onClick={() => {
        if (currentPage !== "home") {
          setCurrentPage("home");
          document.getElementById("#scrollToSignUp")?.scrollIntoView();
        }
      }}
    >
      <a
        href="#scrollToSignUp"
        style={{ color: "inherit" }}
        className="text-decoration-none"
      >
        Join Waitlist
      </a>
    </Button>,
  ];

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {navLinks.map((link) => {
        return <MenuItem>{link}</MenuItem>;
      })}
    </Menu>
  );

  const getCurrentPage = () => {
    switch (currentPage) {
      case "about":
        return <></>;
      case "pricing":
        return <Pricing />;

      case "home":
      default:
        return (
          <Home currentPage={currentPage} setCurrentPage={setCurrentPage} />
        );
    }
  };
  useEffect(() => {
    getCurrentPage();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { sm: "block" }, cursor: "pointer" }}
              onClick={() => setCurrentPage("home")}
            >
              MangoEMR
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {navLinks.map((link) => {
                return <>{link}</>;
              })}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Icon className="fa fa-bars"></Icon>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {getCurrentPage()}
        <Footer />
      </Box>
    </>
  );
}
