import { Box, Button, Grid, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import DoctorsImage from "../../../Images/doctors.svg";
import JoinImage from "../../../Images/join.svg";
import "./Home.css";
import InfoCards from "../../../Components/InfoCards/InfoCards";
import { Global } from "../../../Utilities/Global";
import { notify } from "../../Dashboard/Dashboard";
import { useState } from "react";

interface HomeProps {
  currentPage: any;
  setCurrentPage: React.Dispatch<React.SetStateAction<any>>;
}

export default function Home({ currentPage, setCurrentPage }: HomeProps) {
  const [disableWaitlist, setDistableWaitlist] = useState(false);
  const [email, setEmail] = useState("");
  const joinWaitlist = () => {
    fetch(
      `https://script.google.com/macros/s/AKfycbyJFxA4hLj6OdyqhfH8L49Ec4ApFrxBJsOaH1XrX9K23Q-AHqMPxyoQeUYBGb_PecyI/exec?email=${email}`,
      {
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: null,
      }
    )
      .then((data) => {
        // The response comes here
        setEmail("");
        notify("Email Added, check your email");
      })
      .catch((error) => {
        // Errors are reported there
        notify("Something Went Wrong", "error");
      });
  };

  return (
    <Grid container direction="column">
      <Grid item sx={{ backgroundColor: Global.primaryColor }}>
        <Container className="banner">
          <Row className="mt-5">
            <Col md={6}>
              <h1 className="text-center text-white">MangoEMR</h1>
              <p className="lead text-white">
                Upgrade your software to meet the needs of your patinets and to
                give them the care they deserve.
              </p>
              <Button
                variant="contained"
                sx={{
                  margin: 4,
                  backgroundColor: Global.secondaryColor,
                  ":hover": { backgroundColor: Global.secondaryDarkColor },
                }}
              >
                <a
                  href="#scrollToSignUp"
                  style={{ color: "inherit" }}
                  className="text-decoration-none"
                >
                  Join Waitlist
                </a>
              </Button>
            </Col>
            <Col
              md={6}
              sm={0}
              className="order-md-2 d-none d-sm-none d-md-block"
            >
              <img
                src={DoctorsImage}
                alt="Doctor SVG"
                className="img-fluid"
                width={"500px"}
              />
            </Col>
          </Row>
        </Container>
      </Grid>

      <Grid item sx={{ marginBottom: 15 }}>
        <footer className="footer">
          <div className="waves">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1000 1000"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              overflow="auto"
              shape-rendering="auto"
              fill={Global.primaryColor}
              className="waveUpsideDown"
              id="wave1"
            >
              <defs>
                <path
                  id="wavepath"
                  d="M 0 2000 0 500 Q 150 150 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z"
                />
                <path id="motionpath" d="M -600 0 0 0" />
              </defs>
              <g>
                <use href="#wavepath" y="-39" fill={Global.primaryColor}>
                  <animateMotion dur="5s" repeatCount="indefinite">
                    <mpath href="#motionpath" />
                  </animateMotion>
                </use>
              </g>
            </svg>
          </div>
        </footer>
      </Grid>

      <Grid item>
        <InfoCards />
      </Grid>
      <Grid item sx={{ marginTop: 15 }}>
        {" "}
        <footer className="footer" style={{ marginTop: 4 }}>
          <div className="waves">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1000 1000"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              overflow="auto"
              shape-rendering="auto"
              fill={Global.primaryColor}
              className="wave"
              id="wave1"
            >
              <defs>
                <path
                  id="wavepath"
                  d="M 0 2000 0 500 Q 150 150 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z"
                />
                <path id="motionpath" d="M -600 0 0 0" />
              </defs>
              <g>
                <use href="#wavepath" y="-139" fill={Global.primaryColor}>
                  <animateMotion dur="5s" repeatCount="indefinite">
                    <mpath href="#motionpath" />
                  </animateMotion>
                </use>
              </g>
            </svg>
          </div>
        </footer>
      </Grid>
      <Grid item>
        <Box>
          <Box
            sx={{
              backgroundColor: Global.primaryColor,
              height: "100vh",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div id="scrollToSignUp"></div>

            <Box sx={{ flexGrow: 0.5 }} />

            <Row className="mt-md-5 mr-4 ml-4">
              <Box sx={{ flexGrow: 1 }} />

              <Col xs={12} md={6} className="mt-5">
                <div className="text-center text-md-left">
                  <Typography
                    variant="h3"
                    className="text-white display-4 mt-4 text-center text-md-left"
                    sx={{ marginBottom: 4 }}
                  >
                    The platform your patients deserve.
                  </Typography>
                  {/* <Typography
                  component="p"
                  className="btn btn-primary rounded-pill mb-0 p-2 px-4 mb-4"
                >
                  We are now allowing early-access for users.{" "}
                  <Typography component="u" sx={{ cursor: "pointer" }}>
                    Learn More
                  </Typography>
                </Typography> */}

                  <div className="form-inline form-mobile mb-2">
                    <input
                      className="form-control input-mobile w-50 mr-md-3 mx-0"
                      placeholder="Enter your e-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: Global.secondaryColor,
                        ":hover": {
                          backgroundColor: Global.secondaryDarkColor,
                        },
                      }}
                      disabled={disableWaitlist}
                      onClick={() => {
                        if (email === "") {
                          notify("No Email Provided", "warning");
                          return;
                        }
                        setDistableWaitlist(true);
                        joinWaitlist();
                        setTimeout(() => {
                          setDistableWaitlist(false);
                        }, 5000);
                      }}
                    >
                      Join Waitlist
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={5} className="d-flex justify-content-center ">
                <img
                  src={JoinImage}
                  alt="Join SVG"
                  className="w-75 mt-3 d-none d-md-block"
                />
              </Col>
              <Box sx={{ flexGrow: 1 }} />
            </Row>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
